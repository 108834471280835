$(function () {
  var message = $('.js-alert');
  var close = message.find('.js-alert-close');
  var page = $('body');

  close.on('click', function () {
    message.removeClass('active');
    page.removeClass('no-scroll'); 
  })
})
