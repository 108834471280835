var $ = require('jquery')

require('owl.carousel');

require('./jquery.cookie.js');
require('./warning.min.js');
require('./common.js');

// require('../../sections/suppository/header/header.js')
// require('../../sections/suppository/hero/hero.js')
// require('../../sections/suppository/hero-start/hero-start.js')
// require('../../sections/suppository/slider/slider.js')
// require('../../sections/suppository/useful/useful.js')
// require('../../sections/suppository/article/article.js')

// require('../../components/suppository/effect-list/effect-list.js')
// require('../../components/suppository/step-list/step-list.js')
// require('../../components/suppository/split-imgs/split-imgs.js')
// require('../../components/suppository/dropdown-text/dropdown-text.js')
// require('../../components/suppository/nav-sections/nav-sections.js')
// require('../../components/suppository/effect/effect.js')
// require('../../components/suppository/img-animate/img-animate.js')
// require('../../components/suppository/show-more/show-more.js')
// require('../../components/suppository/tabs/tabs.js')
require('../../../components/start/alert/alert.js');
// require('../../components/suppository/modal/modal.js')

require('./typo.js');
