var Bowser = require('bowser/bundled.js');

$(function () {
  var buttons = $('a, .button');

  buttons.each(function () {
    var button = $(this);

    button
      .on('mousedown', function () {
        button.addClass('press');
      })
      .on('mouseup', function () {
        button.removeClass('press');
      })
  });

  var browser = Bowser.getParser(window.navigator.userAgent);
  var isValidBrowser = browser.satisfies({
    'Android Browser': '>=4',
    "internet explorer": ">=10",
    "Microsoft Edge" : ">=17",
    safari: ">=11",
    chrome: ">=67",
    firefox: ">=61",
    opera: ">=54",
    'Yandex Browser': '>=18',

    mobile: {
      safari: '>=10',
      chrome: '>=66',
      'Android Browser': '>=4',
      'Samsung Internet for Android': '>=3'
    },

    tablet: {
      safari: '>=10',
      chrome: '>=66',
      'Android Browser': '>=4',
      'Samsung Internet for Android': '>=3'
    }
  });

  var browserData = browser.getBrowser();
  var browserAlert = $('.js-alert');
  var page = $('body');

  var isNativeAndroid = (
      (window.navigator.userAgent.indexOf('Mozilla/5.0') > -1 &&
      window.navigator.userAgent.indexOf('Android ') > -1 &&
      window.navigator.userAgent.indexOf('AppleWebKit') > -1) &&
      (window.navigator.userAgent.indexOf('Version') > -1)
  );
  if (isNativeAndroid) { // bowser can't identify native android browser :(
    browserData.name = 'Android Browser';
    var match = window.navigator.userAgent.match(/version\/(\d+(\.?_?\d+)+)/i);
    browserData.version = (match && match.length > 0 && match[1]) || '';
    isValidBrowser = parseFloat(browserData.version) >= 4;
  }

  if (!isValidBrowser) {
    browserAlert.addClass('active');
    page.addClass('no-scroll');
    // alert('Ваш браузер устарел, обновитесь! В противном случае возможно некорректное отображение\n'
    //     + browserData.name + ' ' + browserData.version);
  }

  $('.js_footerYear').text((new Date()).getFullYear());
});

$(window).on('load', function() {
  console.log($('body').attr('style'));
})


$(document).ready(function(e) {
  $('.start_link').on('click', function() {
    var url = $(this).attr('data-target');
    if (url !== 'javascript:void(0);') {
      window.location = $(location).attr("href") + "/" + url;
    } else {
      e.prevent.Default();
    }
  });
});
